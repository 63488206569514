/* CSS for the file input field */
.custom-file-input {
    display: inline-block;
    padding: 6px;
    width: 190px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .custom-file-input:hover {
    background-color: #2980b9;
  }
  .file-name {
    display: inline-block;
    margin-left: 10px;
    font-style: italic;
    color: #555;
  }
  
  .form-control{
    position: relative;
    display: inline-block
  }
  .form-group{
    text-align: center;
    font-size: 12px;
  }
  input[type="datetime-local"] {
    text-align: center;
    padding: 6px;
    border: 2px solid #3498db; 
    border-radius: 5px;
    font-size: 11px;
    width: 180px; /* Adjust width as needed */
    outline: none; /* Remove outline on focus */
  }
  .red-heart {
    color: red;
}
.blinkedbackground,
.jKLQUD{
  background: transparent;
  padding: 3px;
  box-shadow: 0 0 0 0;
}
  /* App.css */
  .animated-message {
    animation: blink 2s infinite; /* Apply the blink animation */
  } 
  
  @keyframes blink {
    0% { opacity: 1;color: red;}
    50% { opacity: 0.2;color: blue; }
    100% { opacity: 1; color: green;}
  } 


