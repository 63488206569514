/* App.css */
/* User bubble color */
.iOCYje{
  background: rgb(246, 237, 237);
}
/* Bot bubble color */
/* .hQsUiY{
  background:rgb(174, 172, 172);
  color: #1b1a1a;
} */
/* height of chatbot */
.fBGGBn {
  height: calc(230px)!important;
  overflow-y: auto!important;
  margin-top: 2px;
  padding-top: 6px;
}
.fLIrXt{
  height: 340px!important;

}
.dPdbIW {
  height: 340px!important;
}
.hwXRO ,
.caBbPK {
  max-width: 60%;
}
.cwuCQv{
  border-radius: 0!important;
}
.bJFamB{
  max-width: 60%!important;
}

.chatbot-container { 
  margin-right: 25px; 
  
}

.chatbot-header {
  height: 35px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: rgb(134, 210, 239); /*Adjust as per your design*/
 
}

.chatbot-logo {
 width: 60px; /*Adjust the size of the logo */
  height: 50px;  
  object-fit: fill; 
  cursor: pointer;
  mix-blend-mode: multiply;
}
.chatbot-logo_offcanvas{
  width: 60px; /* Adjust the size of the logo */
  height: 50px;
  cursor: pointer;
  mix-blend-mode: multiply;
  margin-left: 0.05%;
}

.header-buttons {
  display: flex;
  gap: 10px; /* Adjust spacing between buttons */
}

.close-icon,
.minimize-icon {
  cursor: pointer;
  position: relative;
}
.close-icon{
  color: red;
  font-size: 18px;
}
.minimize-icon{
  color: black;
  font-size: 18px;
  margin-right: 8px;
}

.chatbot-icon {
  position: fixed;
  bottom: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  cursor: pointer;
}
.chatbot-icon img {
  width: 140px; /* Adjust the width as needed */
  height: auto;
}

/* CSS */
.offcanvas-container {
  position: absolute;
  top: 82%;
  right: 10px; /* Ensure it starts from the right side */
  width: 300px;
  border-radius: 10px;
  background-color: rgb(134, 210, 239);
  transition: right 0.3s ease;
  z-index: 1000; 
}

.offcanvas-header {
  height: 50px;
  display: flex; /* Make children display in a row */
  justify-content: space-between; /* Distribute space between children */
  align-items: center; /* Align items vertically */
   /* Add padding for space */
}

.chatbot-logo_offcanvas {
  width: 60px; /* Adjust width as needed */
  height: 50px; /* Adjust height as needed */
  padding: 10px;
}

.header-buttons {
  display: flex; /* Ensure buttons display in a row */
}

.tooltip {
  margin-right: 10px; /* Add spacing between buttons */
}
.maximize-icon{
  cursor: pointer;
}


@media only screen and (min-width: 768px) {
  .chatbot-container {
    position: absolute;
    top: 65%; /* Adjust as needed */
    right: 0;
    transform: translateY(-50%);
  }
}
@media only screen and (max-width: 767px) {
  .chatbot-container {
    width: 95%;
    max-width: 600px; /* Adjust the maximum width as per your design */
    margin: auto;
  }
}